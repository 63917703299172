.PrivacyContainer {
  align-items: left;
  justify-content: left;
  text-align: left;
  align-self: center;
  padding: 50px 500px 50px 500px;
}

@media (max-width: 600px) {
  .PrivacyContainer {
    padding: 10px;
    align-self: initial;
  }
}
