.PlantsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;
}

@media (max-width: 600px) {
  .PlantsContainer {
    padding: 0;
    justify-content: center;
    overflow: hidden;
  }
}

.PlantsTabContainer {
  padding: 0 100px 0 100px;
  min-width: 80%;
  align-self: center;
}

@media (max-width: 600px) {
  .PlantsTabContainer {
    padding: 0 10px 0 10px;
    min-width: initial;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .PlantsTabContainer {
    padding: 0 500px 0 500px;
  }
}

@media (min-width: 1600px) {
  .PlantsTabContainer {
    min-width: 60%;
  }
}

.PlantsTabPanel {
  padding: 10px 0;
}

@media (max-width: 600px) {
  .Paper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.backButton {
  display: flex;
  background: none;
  border: none;
  color: #516e58;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 15px;
}

.backButton > svg:first-child {
  padding-right: 5px;
  margin-top: -2px;
}

.PlantsList {
  overflow-y: scroll;
  height: calc(100vh - 330px);
}

@media (max-width: 600px) {
  .PlantsList {
    height: calc(100vh - 270px);
  }
}

.PlantItemContainer {
  display: flex;
  flex: 1;
  padding: 20px 0;
  cursor: pointer;
}

.PlantItemContainer > div:first-child {
  width: 300px;
  display: flex;
  justify-content: center;
}

.PlantItemContainer.expanded > div:first-child {
  width: 50%;
  height: 100%;
  cursor: initial;
}

.PlantItemContainer.expanded > div > div:first-child {
  align-self: flex-start;
}

@media (max-width: 600px) {
  .PlantItemContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0 10px 0;
  }
}

@media (max-width: 600px) {
  .PlantItemContainer.PlantItemContainer.expanded > div:first-child {
    width: 100%;
  }
}

.ListItemDivider {
  margin: 0 20px !important;
}

.PlantIcon {
  padding-right: 20px;
  height: 200px;
}

.DetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GalleryContainer {
  padding-right: 30px;
}

@media (max-width: 600px) {
  .GalleryContainer {
    padding-right: initial;
    width: initial;
  }
}
