.ImagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 0 10px 0 10px;
}

@media (min-width: 600px) {
  .ImagesContainer {
    align-self: center;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .ImagesContainer {
    width: 30%;
  }
}
