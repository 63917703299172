.ContactDetailsContainer {
  display: flex;
  width: 350px;
  text-align: left;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .ContactDetailsContainer {
    width: auto;
    justify-content: initial;
  }
}

.ContactDetailsRows {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
