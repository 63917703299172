a:link {
  color: initial;
  background-color: transparent;
  text-decoration: none;
}

@media (max-width: 600px) {
  a:link {
    color: #516e58;
  }
}

a:visited {
  color: initial;
  background-color: transparent;
  text-decoration: none;
}

@media (max-width: 600px) {
  a:visited {
    color: #516e58;
  }
}

a:hover {
  color: #516e58;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #516e58;
  background-color: transparent;
  text-decoration: underline;
}

.App {
  font-family: 'Noto Naskh Arabic UI', 'Noto Sans UI', sans-serif;
  font-size: 14pt;
  line-height: 22pt;
  text-align: center;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
}

@media (max-width: 600px) {
  .App {
    font-size: 12pt;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #516e58;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 1px;
  height: 120px;
}

@media (max-width: 600px) {
  .App-header {
    height: 100%;
    flex-direction: column;
  }
}

.App-header-collapsed {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #516e58;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 1px;
  height: 120px;
}

@media (max-width: 600px) {
  .App-header-collapsed {
    flex-direction: column;
  }
}

.Logo {
  display: flex;
}

.Logo-text {
  white-space: nowrap;
  color: #ffffff;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.Navigation {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  color: white;
}

@media (max-width: 600px) {
  .Navigation {
    height: 100%;
    flex-direction: column;
  }
}

.App-link {
  color: #61dafb;
}

.Button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  width: 200px;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (min-width: 600px) {
  .Content {
    flex-grow: 1;
  }
}

.CookieInfo {
  width: 100%;
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 180px;
  background: #516e58;
  position: fixed;
  bottom: 0;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 1px;
}

@media (min-width: 600px) {
  .CookieInfo {
    padding: 0;
    flex-direction: row;
    height: 60px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  padding-top: 20px;
  padding-bottom: 10px;
}

@media (max-width: 600px) {
  footer {
    padding-bottom: 0;
  }
}

footer > div {
  font-size: medium;
}

footer > div:last-child {
  font-size: small;
}

@media (max-width: 600px) {
  footer > div {
    font-size: small;
  }
}
