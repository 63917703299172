.ContactContainer {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
  text-align: left;
  padding-bottom: 10px;
}

@media (min-width: 600px) {
  .ContactContainer {
    align-self: center;
    justify-content: center;
    height: 100%;
  }
}

.ContactColumn {
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  width: 550px;
  display: flex;
}

@media (min-width: 600px) {
  .ContactColumn {
    flex-direction: row;
    padding-bottom: 25px;
  }
}

.ContactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-height: 600px) {
  .ContactForm {
    justify-content: space-between;
  }
}

@media (min-width: 600px) {
  .ContactForm {
    justify-content: center;
  }
}

.Input {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 600px) {
  .Input {
    min-width: 450px;
  }
}

@media (min-width: 600px) {
  .Input {
    flex-direction: row;
  }
}

.Item {
  padding-bottom: 10px;
}
