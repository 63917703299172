.HomeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

@media (max-width: 600px) {
  .Text {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Image {
  align-self: center;
}

@media (max-width: 600px) {
  .Image {
    height: 300px;
  }
}

.ClosedNote {
  background: #fff033;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  position: absolute;
  top: 45%;
}

@media (max-width: 600px) {
  .ClosedNote {
    height: 120px;
    margin: 0 1000px;
  }
}
