.ImprintContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 50px 100px 50px 100px;
}

@media (max-width: 600px) {
  .ImprintContainer {
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .ImprintContainer {
    padding: 50px 500px 50px 500px;
  }
}

.Imprint {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .Imprint {
    flex-direction: column;
  }
}
